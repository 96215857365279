<template>
    <div>
        <main class="app-content-lk">
            <div class="page-lk">
                <div v-if = "getStartChatIsLoading" class = "loader">
                    <div class = "spinner-container">
                        <b-spinner variant = "secondary" />
                    </div>
                </div>
                <div class="container-lk-des">
                    <ClientInfoDesktop/>
                    <ClientInfoMobile/>
                    <div class="title-container">
                        <h2 class="lk-title">{{ $t('Client card') }}</h2>
                        <div class="reload-container" @click="reloadData">
                            <div class="nui-icon nui-icon-reload"></div>
                            <div class="reload-text">{{ $t('Reload data') }}</div>
                        </div>
                    </div>
                    <div class="client__tabs">
                        <div>
                            <b-tabs content-class="tab-container mx-n-27" nav-wrapper-class="tab-nav-wrapper"
                                    v-on:activate-tab="tabActivated">
                                <b-tab active ref="tabItem" lazy :class="{'no-items': noItems}">
                                    <template #title>
                                        <span :data-title="$t('Active policies')">
                                            {{ $t('Active policies') }}
                                        </span>
                                    </template>
                                    <div class="cards-wrapper">
                                        <div v-if="loading.policies" class="spinner-wrapper">
                                            <b-spinner
                                                variant="secondary"
                                                key="secondary"
                                            />
                                        </div>
                                        <div class="no-policy-text" v-if="activePolicies == null || activePolicies.length === 0"
                                             v-html="$t('No policy text')">
                                        </div>
                                        <div class="no-policy-text" v-if="loadingPoliciesError === true"
                                             v-html="$t('Loading policies error')">
                                        </div>
                                        <div v-for="policy in activePolicies" :key="policy.id">
                                            <PoliciesCardActive :policy="policy"
                                                                :calculatorsMapping="calculatorsMapping"
                                                                @fetchPolicies="fetchPolicies"
                                                                @selectInsuranceCase="selectInsuranceCase"
                                            />
                                        </div>
                                    </div>
                                </b-tab>

                                <b-tab :class="{'no-items': inactivePolicies == null || loading.policies}">
                                    <template #title>
                                        <span :data-title="$t('Inactive policies')">
                                            {{ $t('Inactive policies') }}
                                        </span>
                                    </template>
                                    <div class="cards-wrapper">
                                        <div v-if="loading.policies" class="spinner-wrapper">
                                            <b-spinner
                                                variant="secondary"
                                                key="secondary"
                                            />
                                        </div>

                                        <div v-for="policy in inactivePolicies" :key="policy.id">
                                            <PoliciesCardInactive :policy="policy"
                                                                  :calculatorsMapping="calculatorsMapping"/>

                                        </div>

                                    </div>
                                </b-tab>

                                <b-tab :active="activateInsuranceAffairsInProcessTab" @click="fetchInsuranceAffairs('tab-click')" :class="{'no-items': loading.insuranceAffairs}">
                                    <template #title>
                                        <span :data-title="$t('Insurance cases in progress')">
                                            {{ $t('Insurance cases in progress') }}
                                        </span>
                                    </template>
                                    <div class="cards-wrapper">
                                        <b-spinner
                                            v-if="loading.insuranceAffairs"
                                            variant="secondary"
                                            key="secondary"
                                        />
                                        <div v-for="request in insuranceAffairsRequests"
                                             :key="request.request_id">
                                            <InsuranceAffairsRequest :request="request"/>
                                        </div>
                                        <div v-for="request in insuranceEuroprotocolRequests"
                                             :key="request.id">
                                            <InsurancEuroptocolRequest :request="request"/>
                                        </div>
                                        <div v-for="(insuranceAffairs,k) in insuranceAffairsInProcess"
                                             :key="k">
                                            <InsuranceAffairsInProcess @selectInsuranceCase="selectInsuranceCase"
                                                                       :insurance-case="insuranceAffairs"
                                                                       :active="true"/>
                                        </div>
                                    </div>
                                </b-tab>

                                <b-tab @click="fetchInsuranceAffairs('tab-click')" :class="{'no-items': loading.insuranceAffairs}">
                                    <template #title>
                                        <span :data-title="$t('Completed insurance cases')">
                                            {{ $t('Completed insurance cases') }}
                                        </span>
                                    </template>
                                    <div class="cards-wrapper">
                                        <b-spinner
                                            v-if="loading.insuranceAffairs"
                                            variant="secondary"
                                            key="secondary"
                                        />
                                        <div v-for="(insuranceAffairs,k) in insuranceAffairsDone"
                                             :key="k">
                                            <InsuranceAffairsInProcess @selectInsuranceCase="selectInsuranceCase"
                                                                       :insurance-case="insuranceAffairs"
                                                                       :active="false"/>
                                        </div>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                    <div v-if="!this.mobileApp.isWebView()" class="fixed-mob-button mob-991" :class="{'padding-for-tab-bar': this.mobileApp.isWebView()}">
                        <a  @click="onChatOperator"
                            class="client__info-link">{{ $t('Report an insurance case') }}</a>
                    </div>
                </div>
            </div>
        </main>

        <!--модалки-->
        <InsuranceCaseChatOperator v-show="showOperatorChat"/>
        <InsuranceCaseReportModal
            v-if="!this.mobileApp.isWebView()"
            @resetInsuraceAffairs="resetInsuraceAffairs"
            :policy="selectedInsuranceCase"
            @resetInsuranceCase="resetInsuranceCase"
        />
        <InsuranceDetailInfoModal/>
        <CrmInsuranceCaseModal v-if="$store.getters['auth/isOperator']"/>
        <InsuranceCaseInProcess :insurance-case="selectedInsuranceCase"/>
        <b-modal modal-class="modal-application" ref="modalApplication" id="modal-application" centered hide-footer
                 hide-header>
            <div class="close-modal" @click="$bvModal.hide('modal-application')"></div>
            <h3 class="modal-title">Создать заявку</h3>
            <form action="#" method="post" class="application-form">
                <b-tabs v-model="tabIndex">
                    <!-- Render Tabs, supply a unique `key` to each tab -->
                    <b-tab v-for="i in tabs" :key="'dyn-tab-' + i">
                        <template #title>
                            {{ i }}
                            <div
                                v-if="i !== 'Первый участник'"
                                class="close-tab"
                                @click="closeTab(i)">
                            </div>
                        </template>

                        <div class="tab-content">
                            <div v-if="i === 'Первый участник'" class="tab-first">
                                <div class="tab-first-form">
                                    <div class="form-group typeInsurance">
                                        <label class="label">Выберите вид страхования</label>
                                        <!--                                                                <input type="text" class="input">-->
                                        <CustomSelect
                                            :options="['ОГПО', 'не ОГПО', 'Другое']"
                                            :default="'ОГПО'"
                                            class="select"
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label for="name" class="label">Страхователь</label>
                                        <input type="text" id="name" class="input">
                                    </div>
                                    <div class="form-group">
                                        <label for="number" class="label">Гос. номер ТС</label>
                                        <input type="text" id="number" class="input">
                                    </div>
                                    <div class="form-group">
                                        <label for="address" class="label">Адрес СС</label>
                                        <input type="text" id="address" class="input">
                                    </div>
                                    <div class="form-group">
                                        <label for="contact" class="label">Контактное лицо</label>
                                        <input type="text" id="contact" class="input">
                                    </div>
                                    <div class="form-group form-row-50">
                                        <div class="form-group">
                                            <label for="phone" class="label">Телефон</label>
                                            <input type="text" id="phone" class="input">
                                        </div>
                                        <div class="form-group">
                                            <label class="label">Срок страхования</label>
                                            <!--<input type="text" id="term" class="input">-->
                                            <CustomSelect
                                                :options="['1', '3', '5', '10']"
                                                :default="'1'"
                                                class="select"
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="numberPolicy" class="label">Номер полиса / уникальный номер</label>
                                        <input type="text" id="numberPolicy" class="input">
                                    </div>
                                    <div class="form-group">
                                        <label for="insured" class="label">Застрахованный</label>
                                        <input type="text" id="insured" class="input">
                                    </div>
                                    <div class="form-group">
                                        <label for="typeCc" class="label">Тип СС</label>
                                        <input type="text" id="typeCc" class="input">
                                    </div>
                                    <div class="form-group">
                                        <label for="signGuilt" class="label">Признак виновности</label>
                                        <textarea id="signGuilt" class="input textarea"></textarea>
                                    </div>

                                </div>
                            </div>
                            <div v-if="i !== 'Первый участник'" class="tab-other">
                                <h3 class="title">Вид страхования: ОГПО</h3>
                                <div class="tab-other-form">
                                    <div class="form-group form-row-50">
                                        <div class="form-group">
                                            <label for="TC" class="label">ТС</label>
                                            <input type="text" id="TC" class="input">
                                        </div>
                                        <div class="form-group">
                                            <label for="marka" class="label">Марка и модель ТС</label>
                                            <input type="text" id="marka" class="input">
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="label">Вызов ГАИ</label>
                                        <CustomSelect
                                            :options="['Да', 'Нет']"
                                            :default="''"
                                            class="select"
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label class="label">Франшиза</label>
                                        <!--                                                                <input type="text" class="input">-->
                                        <CustomSelect
                                            :options="['Франшиза', 'Другое']"
                                            :default="''"
                                            class="select"
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label for="numDoc" class="label">Номер договора</label>
                                        <input type="text" id="numDoc" class="input">
                                    </div>
                                    <div class="form-group">
                                        <label for="risk" class="label">Риски</label>
                                        <input type="text" id="risk" class="input">
                                    </div>
                                    <div class="form-group">
                                        <label for="sum" class="label">Страховая сумма</label>
                                        <input type="text" id="sum" class="input">
                                    </div>
                                    <div class="form-group form-row-50">
                                        <div class="form-group-m">
                                            <label class="label">Эвакуатор</label>
                                            <div class="form-group-radio">
                                                <div class="radio-wrapper">
                                                    <label class="radio-label">
                                                        <input class="radio-input" type="radio" name="truck" value=""
                                                               checked>
                                                        <span class="radio-style"></span>
                                                        <div class="radio-text">Да</div>
                                                    </label>
                                                </div>
                                                <div class="radio-wrapper">
                                                    <label class="radio-label">
                                                        <input class="radio-input" type="radio" name="truck" value="">
                                                        <span class="radio-style"></span>
                                                        <div class="radio-text">Нет</div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group-m">
                                            <label class="label">Наличие КАСКО</label>
                                            <div class="form-group-radio">
                                                <div class="radio-wrapper">
                                                    <label class="radio-label">
                                                        <input class="radio-input" type="radio" name="CASCO" value=""
                                                               checked>
                                                        <span class="radio-style"></span>
                                                        <div class="radio-text">Да</div>
                                                    </label>
                                                </div>
                                                <div class="radio-wrapper">
                                                    <label class="radio-label">
                                                        <input class="radio-input" type="radio" name="CASCO" value="">
                                                        <span class="radio-style"></span>
                                                        <div class="radio-text">Нет</div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="comment" class="label">Комментарий</label>
                                        <textarea id="comment" class="input textarea"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-tab>

                    <!-- New Tab Button (Using tabs-end slot) -->
                    <template #tabs-end>
                        <b-nav-item role="presentation" @click.prevent=" focusNewTab(); newTab();" href="#"
                                    class="add-tab">
                            <div v-if="tabs.length === 1" class="add-tab-text">
                                <i class="add-tab-icon-sm"></i>
                                Второй учатник
                            </div>
                            <div v-if="tabs.length !== 1" class="add-tab-icon"></div>
                        </b-nav-item>
                    </template>

                </b-tabs>
                <AppButton title="Создать" class="w-162 h-38 mt-20 m-right"/>
            </form>
        </b-modal>
        <b-modal
            modal-class="sign-file-modal-response"
            id="modal-sign"
            centered
            hide-footer
            hide-header>
            <h3 class="modal-title">{{ $t('your document has been successfully signed') }}</h3>
            <button @click="$bvModal.hide('modal-sign')" class="w-202 fs-14 h-38 mt-25">Oк</button>
        </b-modal>
        <b-modal
            modal-class="sign-file-modal-response"
            id="modal-sign-error"
            centered
            hide-footer
            hide-header>
            <h3 class="modal-title">{{ $t('your document has been unsuccessfully signed') }}</h3>
            <button @click="$bvModal.hide('modal-sign-error')" class="w-202 fs-14 h-38 mt-25">Oк</button>
        </b-modal>
        <AlertInfoText/>
        <SigexSignFileModal v-if="isSignPopupVisible" />
        <ChatNotifications/>
    </div>
</template>

<script>
import AppButton from "@/components/AppButton"
import CustomSelect from "@/components/CustomSelect"
import PoliciesCardActive from '@/components/PoliciesCardActive'
import PoliciesCardInactive from '@/components/PoliciesCardInactive'
import InsuranceAffairsInProcess from '@/components/InsuranceAffairsInProcess' //off on master
import ClientInfoDesktop from "@/components/ClientInfoDesktop"
import ClientInfoMobile from "@/components/ClientInfoMobile"
import InsuranceCaseReportModal from '@/components/modals/InsuranceCaseReportModal'
import InsuranceAffairsRequest from "@/components/InsuranceAffairsRequest";
import InsuranceDetailInfoModal from "@/components/modals/InsuranceDetailInfoModal";
import CrmInsuranceCaseModal from "@/components/operator/CrmInsuranceCaseModal";
import InsuranceCaseInProcess from "@/components/modals/InsuranceCaseInProcess";
import SigexSignFileModal from '@/components/modals/SigexSignFileModal.vue';
import InsuranceCaseChatOperator from '@/components/InsuranceCaseChatOperator.vue';

import i18n from "@/plugins/i18n";
import {mapActions, mapGetters} from "vuex";
import AlertInfoText from "@/components/modals/AlertInfoText";
import {insuranceCaseReportModalMixin} from "@/mixins/insuranceCaseReportModalMixin";
import ChatNotifications from '@/components/chat/modal/ChatNotifications.vue';
import { PushNotifications } from '@capacitor/push-notifications';
import InsurancEuroptocolRequest from "@/components/InsuranceEuroprotocolRequest.vue";

export default {
    name: 'LkView',
    metaInfo: {
        title: i18n.tc('Personal Account'),
        titleTemplate: '%s | Nomad.kz'
    },
    data: () => {
        return {
            showOperatorChat: false,
            insuranceCasesOn: false,
            loading: {
                policies: false,
                insuranceAffairs: false,
            },
            calculatorsMapping: {
                dataLab: {
                    323: "propertyInsurance",
                    16: "KaskoDtp",
                    46: "DSGPO",
                    260: "KaskoTransformer",
                    1: "OGPO",
                    10: "ACCIDENT",
                    135: "ACCIDENT_SPORT",
                },
                site: {
                    77: "propertyInsurance",
                    95: "propertyInsurance",
                    76: "KaskoDtp",
                    101: "KaskoDtp",
                    96: "DSGPO",
                    98: "DSGPO",
                    99: "KaskoTransformer",
                    85: "KaskoTransformer",
                    79: "OGPO",
                    90: "OGPO",
                    124: "ACCIDENT",
                    125: "ACCIDENT_SPORT",
                }
            },
            // activePolicies: {},
            // inactivePolicies: {},
            // insuranceAffairsRequests: [],
            // insuranceAffairsInProcess: {},
            // insuranceAffairsDone: {},
            selectedInsuranceCase: {},
            modalShow: false,
            tabsTitle: ['Первый участник', 'Второй участник', 'Третий участник', 'Четвертый участник', 'Пятый участник'],
            tabs: ['Первый участник'],
            tabCounter: 1,
            tabIndex: 1,
            alertInfoText: '',
            haveToOpenInsuranceAffairCard: false,
            openInsuranceAffairCardId: '',
            activateInsuranceAffairsInProcessTab: false,
            loadingPoliciesError: false,
        }
    },
    components: {
        AppButton,
        CustomSelect,
        PoliciesCardActive,
        PoliciesCardInactive,
        InsuranceAffairsInProcess,
        InsuranceCaseReportModal,
        ClientInfoDesktop,
        ClientInfoMobile,
        InsuranceAffairsRequest,
        InsuranceDetailInfoModal,
        CrmInsuranceCaseModal,
        InsuranceCaseInProcess,
        AlertInfoText,
        InsuranceCaseChatOperator,
        SigexSignFileModal,
        ChatNotifications,
        InsurancEuroptocolRequest
    },
    computed: {
        ...mapGetters('auth', ['user']),
        ...mapGetters('mainPage', [
            'activePolicies',
            'inactivePolicies',
            'insuranceAffairsRequests',
            'insuranceAffairsInProcess',
            'insuranceAffairsDone',
            'insuranceEuroprotocolRequests'
        ]),
        ...mapGetters('sign', ['isSignPopupVisible']),
        ...mapGetters('chat', [
            'getStartChatIsLoading'
        ]),
        noItems() {
            return this.activePolicies == null || this.loading.policies || this.loadingPoliciesError
        }
    },
    mixins: [insuranceCaseReportModalMixin],
    methods: {
        ...mapActions({
            setActivePolicies: 'mainPage/setActivePolicies',
            setInactivePolicies: 'mainPage/setInactivePolicies',
            showChat: 'chat/showChat',
            deactivateSign: 'sign/deactivateSign',
            selectChat: 'chat/selectChat',
        }),
        onChatOperator  ()  {
            this.$store.dispatch('chat/startEmptyChat')
        },
        fetchPolicies(resetCache = false) {
            this.resetPolices()
            this.loading.policies = true
            this.$axios.get('/contracts', { params: {reset_cache: +resetCache}}).then(response => {
                this.setActivePolicies(response.data.active)
                this.setInactivePolicies(response.data.inactive)
            }).catch(() => {
                this.loadingPoliciesError = true;
            })
                .finally(() => {
                this.loading.policies = false
                if(this.mobileApp.isWebView()) {
                    window.mobileApp.isPoliciesLoaded = true
                }
            })
        },
        //страховые дела
        //урегулированные страховые выплаты
        fetchInsuranceAffairs(action) {
            if (this.loading.insuranceAffairs)
                return

            if(this.mobileApp.isWebView()) {
                if(action === 'tab-click' && window.mobileApp.isAffairsLoaded)
                    return
            } else {
                if (
                    (Object.keys(this.insuranceAffairsInProcess).length > 0 ||
                    Object.keys(this.insuranceAffairsDone).length > 0 ||
                    Object.keys(this.insuranceAffairsRequests).length > 0)
                    && this.haveToOpenInsuranceAffairCard === false
                ) {
                    return
                }
            }

            this.resetInsuranceAffairs()
            this.loading.insuranceAffairs = true
            this.$axios.post('/insurance-cases/affairs',{iin:this.user.iin}).then(response => {
                let tempArr = []
                response.data.requests?.requests.forEach((request) => {
                    if (request.status_code === this.constants.insuranceCases.docStateCodes.sent)
                        tempArr.push(request)
                })
                this.setInsuranceAffairsRequests(tempArr)
                this.setInsuranceAffairsInProcess(response.data.in_progress.payout_cases)
                this.setInsuranceAffairsDone(response.data.done.payout_cases)
                this.setInsuranceEuroprotocolRequests(response.data.europrotocol_requests)
            }).finally(() => {
                this.loading.insuranceAffairs = false
                if(this.mobileApp.isWebView()) {
                    window.mobileApp.isAffairsLoaded = true
                }
                if(this.haveToOpenInsuranceAffairCard) {
                    let insuranceAffairToOpen = this.insuranceAffairsInProcess.filter(affair => affair.pc_id === this.openInsuranceAffairCardId)
                    if(insuranceAffairToOpen.length < 1) {
                        this.$bvModal.hide('insurance-case-in-process')
                    } else {
                        this.selectInsuranceCase(insuranceAffairToOpen[0])
                    }
                }
            })
        },
        selectInsuranceCase(value) {
            this.selectedInsuranceCase = value
        },
        closeTab(x) {
            for (let i = 0; i < this.tabs.length; i++) {
                if (this.tabs[i] === x) {
                    this.tabs.splice(i, 1)
                }
            }
        },
        newTab() {
            if (this.tabCounter < 5) {
                this.tabs.push(this.tabsTitle[this.tabCounter++])
            }
        },
        focusNewTab() {
            setTimeout(() => {
                this.tabIndex = this.tabs.length
            })
        },
        scrollFix() {
            let scrollBar = document.querySelectorAll('.ps__rail-x')

            for (let i = 0; i < scrollBar.length; i++) {
                scrollBar[i].style.bottom = '0'
            }
        },
        tabActivated(newTabIndex) {
            let curentTab = document.querySelectorAll('.tab-nav-wrapper .nav-item')
            let tabs = document.querySelector('.tab-nav-wrapper .nav-tabs')
            let scroll = document.querySelector('.tab-nav-wrapper')

            let tar = curentTab[newTabIndex]  //Таб на который кликнули
            let x = scroll.clientWidth - 50 - 24  //Ширина блока со скроллом - 50(не понятно) - 24(паддинги)
            let y = tar.offsetWidth  //Ширина текушего таба с учетом отступов
            let z = newTabIndex  //Индекс текушего таба
            let q = 0
            let m = tabs.querySelectorAll('li')

            for (var i = 0; i < z; i++) {
                q += m[i].offsetWidth  //Суммарная ширина табов до текушего
            }

            scroll.scroll({
                left: Math.max(0, q - (x - y) / 2),
                behavior: 'smooth'
            })
        },
        resetPolices() {
            this.setActivePolicies({})
            this.setInactivePolicies({})
        },

        reloadData() {
            this.fetchPolicies(true);
            this.fetchInsuranceAffairs('reload-click')
        },
        resetInsuranceCase() {
            this.selectedInsuranceCase = {}
        },
        resetInsuraceAffairs() {
            // TODO: Implement the method
            console.warn('LkPage: resetInsuraceAffairs() not implemented');
        },
    },
    mounted() {
        if (
            typeof this.$route.query['insurance-case-id'] !== "undefined"
            && (typeof this.$route.query['doc-sign-result'] !== "undefined"
                || typeof this.$route.query['doc-sign-error'] !== "undefined")
        ) {
            this.haveToOpenInsuranceAffairCard = true
            this.openInsuranceAffairCardId = this.$route.query['insurance-case-id']
            this.activateInsuranceAffairsInProcessTab = true
            this.fetchInsuranceAffairs('reload-click')
            this.$bvModal.show('insurance-case-in-process')
        }
        if(this.mobileApp.isWebView()) {


            PushNotifications.requestPermissions().then(result => {
                console.log('result', result)
            }).catch(e => {
                console.error(e)
            })

            if(!window.mobileApp.isPoliciesLoaded)
                this.fetchPolicies()
        } else {
            this.fetchPolicies()
        }
        if(!window.mobileApp.isAffairsLoaded) {
            this.resetInsuranceAffairs()
        }
        this.scrollFix()
        if (this.$route.query.signed === 'true') {
            this.$bvModal.show('modal-sign')
        } else if (this.$route.query.signed === 'false') {
            this.$bvModal.show('modal-sign-error')
        }

        this.deactivateSign()

        if (this.$route["name"] == "showChat") {

            let chatId = this.$route["params"]["chatId"];

            if (!isNaN(chatId)) {
                this.selectChat({id: chatId});
            } else {
                console.error("Invalid chatId:", chatId);
            }
        }

        this.$store.dispatch('heartbeat/startHeartbeat');
        this.$store.dispatch("pushNotifications/initPush")

    },
}
</script>

<style lang="scss" scoped>
.nui-icon.nui-icon-reload {
    -webkit-mask-size: 95%;
    mask-size: 95%;
}
.padding-for-tab-bar {
    bottom: 64px;
}
.spinner-wrapper {
    margin: 10vh 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.7);
    color: rgba(255, 255, 255, 0.7);
    z-index:4;
    .spinner-container {
        transform: translateY(10px);
    }
}
</style>
